
import { IonItem, IonList, IonText } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'LogoItem',
  components: { IonItem, IonList, IonText },
  props: {
    type: {
      type: String,
      default: '1'
    },
  },
  setup(props){
    const textColor = ref(" bright");
    const classVal = ref("1");

    function updateValues () {
      switch (props.type){
        case '1':
          textColor.value = 'bright';
          classVal.value = 'logo-container-type-1';
          break;
        case '2':
          textColor.value = 'medium';
          classVal.value = 'logo-container-type-2';
          break;
        case '3':
          textColor.value = 'medium';
          classVal.value = 'logo-container-type-3';
          break;
      }
    }
    onMounted(() => {
      updateValues();
    });
    return {
      textColor,
      classVal,
    }
  },
});
